import exif from "exif-js";
import loadImage from "blueimp-load-image";
export default {
  name: "CategoriesComponent",
  data() {
    return {
      heightTable: 0,
      bHeightTable: 0,
      tableData: [],
      iCurrentPage: 1,
      iItemsPerPage: 12,
      iNumPages: 0,
      iTotalItems: 0,
      screenHeight: 0,
      color: "red",
      texts: "",
    };
  },
  beforeMount() {
    this.$store.commit("setMenuSidebar", {active:false});
    this.texts = FILE.categoryTexts[this.selectLanguage];
  },
  mounted() {
    this.fillTable();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.getExif();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // muestra el dialog para editar la categoria
    editItem(item) {
      this.$store.commit("setDialogEditCategory", {
        active: true,
        arr: item,
      });
    },
    // muestra dialog para eliminar la categoria
    deleteItem(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.textDialogDelete,
        descipcion: this.texts.delete.textDescription,
        api: `${URI}/api/v1/${this.selectLanguage}/categories/${item.sCategoryId}`,
      });
    },
    // muestra las categorias disponibles
    fillTable() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/categories`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          sCategoryTypeId: this.sCategoryId,
          // tStart: this.startDate,
          // tEnd: this.endDate,
        },
      })
        .then((response) => {
          this.tableData = response.data.results.map((e) => {
            return {
              ...e,
              // sImage: exif.getData(e.oImages.sm)
              sImage: e.oImages.sm,
            };
          });

          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize() {
      this.screenHeight = window.innerHeight - 400;
    },

    getExif(sImage) {
      // if (sImage !== undefined) {
      //   let fileList = [];
      //   fileList.push({ file: sImage, orientation: 1 });
      //   const thumbImgs = document.getElementsByClassName("thumb-img");
      //   const fixImgs = document.getElementsByClassName("fix-img");

      //   const setOrientation = () => {
      //     fileList.forEach((img, idx) => {
      //       img.file,
      //         (canvas, data) => {
      //           thumbImgs[idx].innerHTML = "";
      //           fixImgs[idx].innerHTML = "";
      //           thumbImgs[idx].appendChild(canvas);

      //           if (loadImage.orientation && data.exif) {
      //             const orientation = data.exif.get("Orientation");
      //             const thumbCanvas = document.createElement("canvas");

      //             let width = orientation > 4 ? canvas.height : canvas.width;
      //             let height = orientation > 4 ? canvas.width : canvas.height;

      //             thumbCanvas.width = width;
      //             thumbCanvas.height = height;

      //             const ctx = thumbCanvas.getContext("2d");

      //             switch (orientation) {
      //               case 1:
      //                 break;
      //               case 2:
      //                 ctx.translate(width, 0);
      //                 ctx.scale(-1, 1);
      //                 break;
      //               case 3:
      //                 ctx.translate(width, height);
      //                 ctx.rotate(Math.PI);
      //                 break;
      //               case 4:
      //                 ctx.translate(0, height);
      //                 ctx.scale(1, -1);
      //                 break;
      //               case 5:
      //                 ctx.rotate(0.5 * Math.PI);
      //                 ctx.scale(1, -1);
      //                 break;
      //               case 6:
      //                 // ctx.rotate(0.5 * Math.PI)
      //                 // ctx.translate(0, -height)
      //                 ctx.rotate(-0.5 * Math.PI);
      //                 ctx.translate(-width + height / 2, 0);
      //                 break;
      //               case 7:
      //                 // ctx.rotate(0.5 * Math.PI)
      //                 // ctx.translate(width, -height)
      //                 ctx.rotate(0.5 * Math.PI);
      //                 ctx.translate(width - height / 2, -width);
      //                 ctx.scale(-1, 1);
      //                 break;
      //               case 8:
      //                 // ctx.rotate(-0.5 * Math.PI)
      //                 // ctx.translate(-width, 0)
      //                 ctx.rotate(0.5 * Math.PI);
      //                 ctx.translate(0, -width);
      //                 break;
      //               default:
      //                 break;
      //             }
      //             ctx.drawImage(canvas, 0, 0);
      //             fixImgs[idx].appendChild(thumbCanvas);
      //           } else {
      //             const thumbCanvas = document.createElement("canvas");

      //             thumbCanvas.width = canvas.width;
      //             thumbCanvas.height = canvas.height;

      //             const ctx = thumbCanvas.getContext("2d");
      //             ctx.drawImage(canvas, 0, 0);
      //             fixImgs[idx].appendChild(thumbCanvas);
      //           }
      //         },
      //         {
      //           meta: true,
      //           maxWidth: 200,
      //           maxHeight: 200,
      //           canvas: true,
      //         };
      //     });
      //   };
      // }
    },
  },
  computed: {
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    sCategoryId() {
      return this.$store.state.sCategoryId;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    refreshTable: function() {
      this.fillTable();
    },
    sSearch: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    sCategoryId: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    iCurrentPage: function() {
      this.fillTable();
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.categoryTexts[this.selectLanguage];
      }
      this.fillTable();
    },
  },
};
